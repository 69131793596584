import SiteWrapper from "../../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../../assets/images/pagerduty_dxable_og.png";

import MdRenderer from "../../components/mdrender";
import * as md from "../../components/mdrender.module.sass";
import TitleBlock from "../../components/title";

import "./process-software@gen.scss";

const GeneratedAutomationProcessSoftware = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          PagerDuty Process Automationソフトウェア｜正規販売代理店（株）Digital
          Stacks
        </title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="PagerDuty Process Automationソフトウェアでは、システムとインフラの柔軟性を最大限に高めるために自己管理することができます。今すぐお試しください。"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="PagerDuty Process Automationソフトウェアでは、システムとインフラの柔軟性を最大限に高めるために自己管理することができます。今すぐお試しください。"
        />

        <meta
          property="og:title"
          content="PagerDuty Process Automationソフトウェア｜正規販売代理店（株）Digital Stacks"
        />

        <meta
          property="og:description"
          content="PagerDuty Process Automationソフトウェアでは、システムとインフラの柔軟性を最大限に高めるために自己管理することができます。今すぐお試しください。"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/automation/process-software/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/automation/process-software/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow="Automation"
        textGreen="PagerDuty Process Automationソフトウェア"
        overview=""
      />

      <div className="itoperationsWrapper">
        <div className="itoperations-container">
          <div className="mt-0 center-1">
            <h3 className="center-title md:mb-[0px] mb-[30px]">
              ビジネスとITのプロセスを標準化・自動化することで、より迅速な運用を実現します。
            </h3>
          </div>
          <div className="three-col-blog-card-image-grey bg-transparent py-0 my-0 md:mt-[50px]">
            <div className="card">
              <div className="icon-section mb-[30px] min-h-[100px]">
                <svg
                  className="w-[80px] aspect-square"
                  xmlns="http://www.w3.org/2000/svg"
                  width={80}
                  height={80}
                  viewBox="0 0 80 80"
                  fill="none"
                >
                  <path
                    d="M61.3423 28.6594C64.3856 28.6594 66.8527 26.1923 66.8527 23.149C66.8527 20.1058 64.3856 17.6387 61.3423 17.6387C58.2991 17.6387 55.832 20.1058 55.832 23.149C55.832 26.1923 58.2991 28.6594 61.3423 28.6594Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M50.0383 38.2105L43.5973 49.2928C43.3922 49.2698 43.1914 49.2311 42.9803 49.2311C42.4781 49.2311 42.0017 49.3202 41.5395 49.4448L30.0656 35.2258C30.4609 34.4667 30.7056 33.6158 30.7056 32.7002C30.7056 29.6566 28.2389 27.1898 25.1953 27.1898C22.1517 27.1898 19.685 29.6566 19.685 32.7002C19.685 33.933 20.1041 35.0608 20.7884 35.9792L6.08156 61.7786C5.89062 61.7584 5.70625 61.7211 5.51031 61.7211C2.46672 61.7213 0 64.188 0 67.2316C0 70.2752 2.46672 72.7419 5.51031 72.7419C8.55391 72.7419 11.0206 70.2752 11.0206 67.2316C11.0206 65.9975 10.6009 64.8711 9.91656 63.9527L24.6234 38.1531C24.8144 38.1733 24.9995 38.2106 25.1953 38.2106C25.6975 38.2106 26.1747 38.1217 26.6369 37.9969L38.1102 52.2161C37.7148 52.9752 37.4702 53.8261 37.4702 54.7416C37.4702 57.7852 39.9369 60.2519 42.9805 60.2519C46.0241 60.2519 48.4908 57.7852 48.4908 54.7416C48.4908 53.5247 48.0841 52.4097 47.4153 51.4972L53.8461 40.4334C52.4987 39.8522 51.2258 39.1031 50.0383 38.2105Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M78.8036 36.1844L74.4658 32.0616L74.6623 31.7589C76.3211 29.1989 77.198 26.2212 77.198 23.1461C77.198 18.8956 75.5463 14.905 72.5486 11.9073C69.5508 8.90968 65.5609 7.25812 61.3113 7.25812C57.0631 7.25812 53.0709 8.90968 50.0719 11.9073C47.0736 14.908 45.4219 18.8986 45.4219 23.1461C45.4219 27.3951 47.0734 31.3858 50.0711 34.3862C53.0709 37.3839 57.063 39.0355 61.3113 39.0355C64.383 39.0355 67.3611 38.1573 69.9255 36.497L70.2283 36.3005L74.3739 40.66C75.0183 41.4392 75.8377 41.8669 76.6814 41.8669C77.4677 41.8669 78.2441 41.5067 78.9256 40.825C79.6561 40.0975 80.0378 39.225 79.9975 38.3712C79.9603 37.5576 79.5556 36.81 78.8036 36.1844ZM67.8189 29.6551C66.0797 31.393 63.7688 32.3501 61.3113 32.3501C58.8547 32.3501 56.5436 31.3931 54.8038 29.6539C53.0659 27.9175 52.1088 25.6073 52.1088 23.1462C52.1088 20.6866 53.0659 18.3764 54.8038 16.64C56.5438 14.9008 58.8547 13.9437 61.3113 13.9437C63.768 13.9437 66.0784 14.9008 67.8203 16.64C71.4064 20.2289 71.4064 26.0664 67.8189 29.6551Z"
                    fill="#53B54B"
                  />
                </svg>
              </div>
              <h4 className="title">専門知識から再現可能な自動化を</h4>
              <p className="para">
                手動手順を標準化して自動化し、既存の自動化をエンドツーエンドのプロセスに組み込めます。
              </p>
            </div>
            <div className="card">
              <div className="icon-section mb-[30px] min-h-[100px]">
                <svg
                  className="w-[80px] aspect-square"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 80 72"
                  fill="none"
                >
                  <path d="M80 70.5361H0V71.7861H80V70.5361Z" fill="#53B54B" />
                  <path
                    d="M17.6074 56.4712V65.9634H29.8926V53.4878C25.9057 54.6987 21.809 55.7095 17.6074 56.4712Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M1.35742 58.1728V65.9633H13.6426V57.0986C9.62398 57.6748 5.52477 58.0361 1.35742 58.1728Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M33.8184 52.2061V65.9634H46.1816V47.0156C42.224 48.9858 38.0957 50.7217 33.8184 52.2061Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M50.0488 44.9844V65.9634H62.4512V36.8667C58.5741 39.8451 54.4287 42.56 50.0488 44.9844Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M66.2891 33.7612V65.9634H78.7109V20.8364C75.0611 25.497 70.8936 29.8233 66.2891 33.7612Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M64.5434 5.01374L68.8647 8.63437C55.1489 25.7486 28.9673 47.4258 0 50.6509V54.1105C28.7305 53.0948 59.5483 33.4147 75.5542 14.2398L80 17.963V0.213898L64.5434 5.01374Z"
                    fill="#53B54B"
                  />
                </svg>
              </div>
              <h4 className="title">運用チームの生産性を改善</h4>
              <p className="para">
                エンドユーザーやスタッフに安全に操作を委譲し、専門家へのエスカレーションを削減できます。
              </p>
            </div>
            <div className="card">
              <div className="icon-section mb-[30px] min-h-[100px]">
                <svg
                  className="w-[80px] aspect-square"
                  xmlns="http://www.w3.org/2000/svg"
                  width={80}
                  height={80}
                  viewBox="0 0 80 80"
                  fill="none"
                >
                  <path
                    d="M8.62125 71.1091V2.58969V0H0V2.58969V71.1091V77.4103V80H2.51094H8.62125H75.0644H77.5756V71.1091H75.0644H8.62125Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M34.572 37.4906L49.7788 52.6197L71.6382 30.8716L76.6285 35.8366L80.0004 15.7053L59.7666 19.0609L64.7566 24.025L49.7788 38.9267L34.572 23.7977L11.2598 46.9894L18.1413 53.8364L34.572 37.4906Z"
                    fill="#53B54B"
                  />
                </svg>
              </div>
              <h4 className="title">イノベーションの能力を向上</h4>
              <p className="para">
                手動タスクを自動化し、労力を削減して開発業務時間を拡大。ビジネスとITの変革をサポートします。
              </p>
            </div>
          </div>
          <div className="text-center mt-[100px] mb-[30px]">
            <h3 className="relative center-title max-w-max">
              製品機能
              <div className="absolute w-[30%] h-[1.5px] bottom-[-10px] mx-auto bg-[#4EB346]"></div>
            </h3>
          </div>
          <div className="two-col-media-contents gap-[50px] mt-[0px] md:mt-[10px]">
            <div className="text-part">
              <p className="title">既存のITインフラとシステムにプラグイン</p>
              <p className="para">
                Process
                Automationは、多くのプラグインをデフォルトで提供しており、既存のインフラやシステムと統合可能です。独自のプラグインを非常に簡単に作成でき、Webhookを使用して統合することもできます。これらの統合は、入力、ノード、ステップ、出力として自動化されたジョブのリソースとなります。
              </p>
            </div>
            <div className="p-0 media-contents-part">
              <img
                id="fullImage"
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Automation/Process_software/process_automation_plugins_1_91b60e8d9e.png?ik-sdk-version=javascript-1.4.3&updatedAt=1673320627493&tr=w-480,q-100,f-webp"
                alt="automation image"
              />
            </div>
          </div>
          <div className="two-col-media-contents gap-[50px] mt-[60px] md:mt-[60px]">
            <div className="text-part">
              <p className="title">マルチステップの自動化プロセスを定義</p>
              <p className="para">
                エンジニアは、ノードやノードで実行するステップなどのリソースを組み込んだマルチステップのジョブを定義します。ステップには、既存の自動化の実行、API
                呼び出し、コマンドの発行などが含まれます。
              </p>
            </div>
            <div className="p-0 media-contents-part">
              <img
                id="fullImage"
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Automation/Process_software/automation_ruleset_visualizer_1_89b305413f.png?ik-sdk-version=javascript-1.4.3&updatedAt=1673320627639&tr=w-480,q-100,f-webp"
                alt="auto motion iamge 3"
              />
            </div>
          </div>
          <div className="two-col-media-contents gap-[50px] mt-[60px] md:mt-[60px]">
            <div className="text-part">
              <p className="title">セルフサービス型の自動化業務を安全に委任</p>
              <p className="para">
                関係者は、UIから自動化を呼び出すか、プラグイン統合を使用して、ServiceNow、JIRA、Slack、PagerDutyなどの一般的な運用インターフェイスから直接自動化を呼び出すことができます。自動化の実行権限は、ロールベースおよびコンテキストベースのアクセスコントロールに従います。
              </p>
            </div>
            <div className="p-0 media-contents-part">
              <img
                id="fullImage"
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Automation/Process_software/delegate_tasks_to_stakeholders_REVISED_1_20c6cf36de.png?ik-sdk-version=javascript-1.4.3&updatedAt=1673320627377&tr=w-480,q-100,f-webp"
                alt="autoMotionImage"
              />
            </div>
          </div>
          <div className="two-col-media-contents gap-[50px] mt-[60px] md:mt-[60px]">
            <div className="text-part">
              <p className="title">統合されたセキュリティーと内蔵のロギング</p>
              <p className="para">
                Process
                Automationは、認証とアクセスコントロールのためにSSOとLDAPを統合しています。また、シークレット管理システムと統合し、きめ細かなアクセス制御も可能です。内蔵のログ機能により、全てのジョブの実行と結果を監査証跡として残すことができます。
              </p>
            </div>
            <div className="p-0 media-contents-part">
              <img
                id="fullImage"
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Automation/Process_software/rb2_2_ef903002ad.png?ik-sdk-version=javascript-1.4.3&updatedAt=1673320627559&tr=w-480,q-100,f-webp"
                alt="autoMotionImage 4"
              />
            </div>
          </div>
          <div className="two-col-media-contents gap-[50px] mt-[60px] md:mt-[60px]">
            <div className="text-part">
              <p className="title">高可用性、自己管理型デプロイメント</p>
              <p className="para">
                ロードバランサーとサーバーの冗長性を備えたProcess
                Automationクラスターを導入することで、容量と耐障害性を向上させることができます。Process
                Automationは、あらゆるクラウド環境または仮想環境に導入できます。
              </p>
            </div>
            <div className="p-0 media-contents-part">
              <img
                id="fullImage"
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Automation/Process_software/rb5_1_6991b9892d__1_.png?ik-sdk-version=javascript-1.4.3&updatedAt=1673320627762&tr=w-480,q-100,f-webp"
                alt="auttion"
              />
            </div>
          </div>
          <div className="center-2 mb-[80px]">
            <p className="text-center">
              PagerDuty Process
              Automationは、企業のファイアウォール内にインストールされるセルフホスティングの開発およびランタイム環境です。プラグインアーキテクチャーにより、既存のITインフラ、アプリケーション、セキュリティと統合可能です。ロードバランサーの後ろに冗長サーバーを配置した高可用性構成でデプロイすることができます。
            </p>
            <p className="center-para">
              ジョブ開発者は、ウェブブラウザー経由でデザインタイム環境にアクセスできます。ジョブ実行者は、ウェブブラウザー、コマンドライン、または他のアプリケーションからのAPI呼び出しによって自動化されたジョブを呼び出すことができます。自動化されたジョブは、時間や条件に応じて実行するようスケジュールしたり、イベントや他のビジネスプロセスによって起動させることができます。
            </p>
            <p className="center-para">
              PagerDuty Process
              Automationは、自動化に関わるノードを常に監視し、インフラの状態の変化にジョブが動的に適応し、反応することを可能にします。
            </p>
            <img
              className="p-4 mt-[30px] md:p-0"
              src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Automation/Process_software/process_automation_1_df578befec.png?ik-sdk-version=javascript-1.4.3&updatedAt=1673320627515&tr=w-1080,q-100,f-webp"
              alt="automation"
            />
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedAutomationProcessSoftware;
